import './inc/foundation';
import './inc/dynamic-adaptive'
import './animations.js'
import MatchHeight from 'matchheight';

$(document).foundation()

$(document).on('keydown', function (e) {
	if ((e.metaKey || e.ctrlKey) && String.fromCharCode(e.which).toLowerCase() === 'm') {
		$('.mesh').toggleClass('hide');
	}
});

new MatchHeight('.wp-block-dreamdev-blog .tag_list');

